import React, { Component } from "react";
import { Menu, Clock, Settings } from "react-feather";
import { Popover } from "antd";
import Timing from "./settings/Timing";
import StoreSettings from "./settings/StoreSettings";
import { AppContext } from "./contexts/AppContext";

export default class NavBar extends Component {
  static contextType = AppContext;
  state = {
    adminEmail: "",
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  addAdmin = () => {
    const adminData = {
      email: this.state.adminEmail,
    };
    fetch(
      "https://us-central1-ordering-eaa87.cloudfunctions.net/widgets/addAdmin",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(adminData),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.message) {
          console.log(data.message);
        } else {
          console.log("Failed");
        }
      });
  };

  render() {
    return (
      <div className="nav-bar">
        {/* <input
          type="email"
          name="email"
          id="adminEmail"
          value={this.state.adminEmail}
          onChange={this.onChange}
        />
        <button onClick={this.addAdmin}>Add Admin</button> */}
        <button onClick={this.props.click}>
          <Menu size={30}></Menu>
        </button>
        {this.context.admin ? (
          <React.Fragment>
            <div className="settings-bar">
              <Popover
                placement="topLeft"
                content={<Timing></Timing>}
                trigger="click">
                <Clock></Clock>
              </Popover>
              <Popover
                placement="topLeft"
                content={<StoreSettings></StoreSettings>}
                trigger="click">
                <Settings></Settings>
              </Popover>
            </div>
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}
