import React, { Component } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Save, Plus, X, Edit } from 'react-feather';
import { motion } from "framer-motion"
import { db } from '../Firebase/firebase';


export default class Categories extends Component {
    constructor(props) {
        super(props);

        this.state = {
            categories: [],
            currentItems: [],
            currentCategory: "",
            add: false,
            newCategory: "",
            editingCatName: false,
            catNameEdit: '',
        };
    }

    onChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    };

    componentDidMount() {
        this.setState({ categories: this.props.data })
    }


    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.setState({ categories: this.props.data })
        }
    }

    addCategory = () => {
        if (this.state.newCategory !== "") {
            let currentList = [...this.state.categories];
            let newList = [...currentList, { name: this.state.newCategory, order: this.state.categories.length }];
            this.setState({ categories: newList, add: false, newCategory: "" });
        }
        else {
            return;
        }
    }

    deleteCategory = () => {
        let currentList = [...this.state.categories];
        currentList.splice(currentList.findIndex((item) => item.name === this.state.currentCategory), 1);
        this.setState({ categories: currentList, currentCategory: "" })
    }

    onDragEnd = result => {
        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        let newCategories = [...this.state.categories];
        newCategories.splice(source.index, 1);
        newCategories.splice(destination.index, 0, { name: draggableId, order: destination.index });

        this.setState({ categories: newCategories });
    }

    loadCatItems = (cat) => {
        let allItems = [...this.props.items];
        let catItems = allItems.filter(item => {
            return item.category === cat;
        }).sort((a, b) => {
            if (a.order < b.order) {
                return -1;
            } else {
                return 1;
            }
        });
        this.setState({ currentItems: catItems });
    }

    onDragEnd2 = result => {
        //
        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        let newItems = [...this.state.currentItems];
        let removed = newItems.splice(source.index, 1);
        newItems.splice(destination.index, 0, removed[0]);
        this.setState({ currentItems: newItems });
    }

    updateCatName = () => {
        let newCats = [...this.state.categories];
        let toEditIndex = newCats.findIndex((cat) => cat.name === this.state.currentCategory);
        newCats.splice(toEditIndex, 1, { name: this.state.catNameEdit, order: newCats[toEditIndex].order });

        db.collection('Menus').doc(this.props.menuId).update({
            categories: newCats
        })
        this.state.currentItems.map(item => {
            db.collection('Menu').doc(item.id).update({
                category: this.state.catNameEdit
            })
        })

        this.setState({ currentCategory: this.state.catNameEdit })
    }

    render() {
        return (
            <div id="categories-box">
                <div className="category-list">
                    <div className="category-actions">
                        <h1>Categories</h1>
                        <motion.button
                            className="save-btn"
                            whileHover={{ backgroundColor: "#47e689", color: "#fff" }}
                            whileTap={{ scale: 0.9 }}
                            onClick={() => this.props.update(this.state.categories, this.state.currentItems)}
                        >
                            <Save></Save> Save
                        </motion.button>
                    </div>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="category-list">
                            {(provided) => (
                                <div
                                    className="categories-list"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {this.state.categories.map((category, index) => {
                                        return (
                                            <Draggable draggableId={category.name} key={category.name} index={index}>
                                                {(provided) => (
                                                    <span
                                                        className={this.state.currentCategory === category.name ? "cat-active cat-name" : "cat-name"}
                                                        onClick={() => {
                                                            this.setState({ currentCategory: category.name, catNameEdit: category.name })
                                                            this.loadCatItems(category.name);
                                                        }
                                                        }
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        {category.name}
                                                    </span>
                                                )}
                                            </Draggable>
                                        )
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>

                    </DragDropContext>
                    <hr style={{ width: "100%" }} />
                    {!this.state.add ? <motion.button whileHover={{ backgroundColor: "#bc9b5d", color: "#fff" }}
                        whileTap={{ scale: 0.9 }} className="new-cat-btn" onClick={() => this.setState({ add: true })}><Plus></Plus>New Category</motion.button> : <button onClick={() => this.setState({ add: false })}><X></X></button>}
                    {this.state.add ? <div id="new-cat"><input onChange={this.onChange} type="text" name="newCategory" id="newCategory" /><button onClick={this.addCategory}>add</button></div> : null}

                </div>
                <div className="category-items">
                    {this.state.currentCategory !== '' ? <h1>{!this.state.editingCatName ?
                        <span style={{ color: "#bc9b5d" }}>{this.state.currentCategory} <Edit onClick={() => this.setState({ editingCatName: true })}></Edit></span>
                        : <input onChange={this.onChange} type="text" name="catNameEdit" id="catNameEdit" value={this.state.catNameEdit} />} {!this.state.editingCatName ? <button onClick={this.deleteCategory}>Delete Category</button> : <button onClick={this.updateCatName}>Save</button>}
                    </h1> : null
                    }

                    <DragDropContext onDragEnd={this.onDragEnd2}>
                        <Droppable droppableId="category-menu-items">
                            {provided => (
                                <div
                                    className="category-item-list"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {this.state.currentCategory !== "" ? this.state.currentItems.map((item, index) => {
                                        return (
                                            <Draggable draggableId={item.id} key={item.id} index={index}>
                                                {provided => (
                                                    <span
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        ref={provided.innerRef}
                                                    >{item.name}
                                                    </span>
                                                )}
                                            </Draggable>
                                        )
                                    }) : null}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>

                    </DragDropContext>

                </div>
            </div >
        )
    }
}
