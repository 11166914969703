import React, { Component } from "react";
import { db } from "../Firebase/firebase";
import MenuItem from "./MenuItem";
import { Edit2, Plus, Search } from "react-feather";
import Edit from "./Edit";
import Categories from "./Categories";
import NewItem from "./NewItem";
import { message } from "flwww";
import { motion } from "framer-motion";
import Timings from "./Timings";
export default class Menu extends Component {
  state = {
    name: "",
    timings: [],
    items: [],
    categories: [],
    currentEdit: "categories",
    currentItem: "",
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      db.collection("Menus")
        .doc(this.props.match.params.id)
        .get()
        .then((doc) => {
          this.setState({
            ...doc.data(),
          });
        });
      db.collection("Menu")
        /* .where("outlet", "==", "the-coastal-settlement") */
        .where("menu", "==", this.props.match.params.id)
        .onSnapshot((querySnapShot) => {
          let items = [];
          querySnapShot.forEach((item) => {
            let itemData = item.data();
            items.push({ ...itemData, id: item.id });
          });
          this.setState({
            items,
          });
          console.log(items);
        });
    }
  }

  categoriesUpdate = (newList, items) => {
    db.collection("Menus").doc(this.props.match.params.id).update({
      categories: newList,
    });

    items.map((item, index) => {
      db.collection("Menu").doc(item.id).update({
        order: index,
      });
    });
    message("Changes Saved!", "success", 4);
  };

  selectEdit = (id) => {
    this.setState({ currentItem: id, currentEdit: "item" });
  };

  duplicateItems = () => {
    this.state.items.map((item) => {
      let newItem = { ...item };
      newItem.outlet = "TEST";
      delete newItem.id;
      db.collection("Menu").add(newItem);
    });
  };

  changeItemsMenu = () => {
    let itemsToChange = [...this.state.items];
    itemsToChange.map((item) => {
      if (Array.isArray(item.menu)) {
        if (item.menu.some((element) => element === "alacarte")) {
          db.collection("Menu").doc(item.id).update({
            menu: this.props.match.params.id,
          });
        }
      } else {
        if (item.menu === "alacarte") {
          db.collection("Menu").doc(item.id).update({
            menu: this.props.match.params.id,
          });
        }
      }
    });
  };

  render() {
    let { name, timings, items, categories, currentEdit } = this.state;
    let menuItems = items.filter((item) => {
      if (Array.isArray(item.menu)) {
        return item.menu.some(
          (element) => element === this.props.match.params.id
        );
      } else {
        return item.menu === this.props.match.params.id;
      }
    });
    let orderedItems = categories.map((cat) => {
      let catItems = menuItems
        .filter((item) => {
          return item.category === cat.name;
        })
        .sort((a, b) => {
          if (a.order < b.order) {
            return -1;
          } else {
            return 1;
          }
        });
      return [cat, ...catItems];
    });
    console.log(orderedItems);
    return (
      <div className="menu-page">
        <div className="menu-page-col-1">
          {/* <button onClick={this.changeItemsMenu}>update</button> */}
          <h2>{name}</h2>
          {/* <div className="menu-timings">
                        <motion.button whileHover={{ backgroundColor: "#bc9b5d", color: "#fff" }}
                            whileTap={{ scale: 0.9 }} onClick={() => this.setState({ currentEdit: "timings" })}><Edit2></Edit2>Edit Timings</motion.button>
                    </div> */}
          <div className="categories">
            {/* {categories.map(category => {
                            return <span>{category.name}</span>
                        })} */}
            <motion.button
              whileHover={{ backgroundColor: "#bc9b5d", color: "#fff" }}
              whileTap={{ scale: 0.9 }}
              onClick={() => this.setState({ currentEdit: "categories" })}>
              <Edit2></Edit2>Edit Categories
            </motion.button>
          </div>
          <hr />
          <div className="list-bar">
            <motion.button
              onClick={() => this.setState({ currentEdit: "newitem" })}
              whileHover={{ backgroundColor: "#bc9b5d", color: "#fff" }}
              whileTap={{ scale: 0.9 }}>
              <Plus></Plus>New Item
            </motion.button>
            <div className="search-bar">
              <Search></Search>
              <input type="text" name="search" id="search" />
            </div>
          </div>
          <div className="menu-item-list">
            {orderedItems.map((items) => {
              return (
                <React.Fragment>
                  {items.map((item, index) => {
                    if (index === 0) {
                      return <h1>{item.name}</h1>;
                    } else {
                      return (
                        <MenuItem
                          key={item.id}
                          data={item}
                          selectEdit={() =>
                            this.selectEdit(item.id)
                          }></MenuItem>
                      );
                    }
                  })}
                </React.Fragment>
              );
            })}
            {/* {menuItems.map(item => {
                            return <MenuItem key={item.id} data={item} selectEdit={() => this.selectEdit(item.id)}></MenuItem>
                        })} */}
          </div>
        </div>
        <div className="menu-page-col-2">
          <div className="editbox">
            {currentEdit === "categories" ? (
              <Categories
                menuId={this.props.match.params.id}
                update={this.categoriesUpdate}
                items={items}
                data={categories}></Categories>
            ) : currentEdit === "timings" ? (
              <Timings menuId={this.props.match.params.id}></Timings>
            ) : currentEdit === "item" ? (
              <Edit
                itemId={this.state.currentItem}
                categories={categories}></Edit>
            ) : currentEdit === "newitem" ? (
              <NewItem
                categories={categories}
                menu={this.props.match.params.id}></NewItem>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
