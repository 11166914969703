import React, { Component } from "react";
import { Switch } from "antd";
import config from "../../config";
import { db } from "../Firebase/firebase";

export default class StoreSettings extends Component {
  state = {
    storeIsActive: false,
  };
  componentDidMount() {
    let docRef = config.outlet === "TCS" ? "operationsTCS" : "operationsCMPB";
    db.collection("Settings")
      .doc(docRef)
      .onSnapshot((doc) => {
        this.setState(doc.data());
      });
  }
  onChange(checked) {
    let docRef = config.outlet === "TCS" ? "operationsTCS" : "operationsCMPB";
    console.log(checked);
    db.collection("Settings").doc(docRef).update({
      storeIsActive: checked,
    });
  }
  render() {
    return (
      <div className="store-settings-page">
        <span>Enable Store</span>
        <Switch
          onClick={this.onChange}
          checked={this.state.storeIsActive}></Switch>
      </div>
    );
  }
}
