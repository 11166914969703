import React, { Component, createContext } from "react";
import { db } from "../Firebase/firebase";
export const AppContext = createContext();

export default class AppContextProvider extends Component {
  state = {
    outlet: "TCS",
    storeIsActive: false,
    admin: false,
  };

  componentDidMount() {
    db.collection("Settings")
      .doc("operationsTCS")
      .onSnapshot((snapshot) => {
        this.setState({ storeIsActive: snapshot.data().storeIsActive });
      });
  }

  setAdmin = (bool) => {
    this.setState({ admin: bool });
  };

  setOutlet = (outlet) => {
    this.setState({
      outlet,
    });
  };
  render() {
    return (
      <AppContext.Provider
        value={{
          ...this.state,
          setOutlet: this.setOutlet,
          setAdmin: this.setAdmin,
        }}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}
