import React, { Component } from "react";
import { TimePicker, Select } from "antd";
import { db } from "../Firebase/firebase";
import config from "../../config";
import moment from "moment";

const { Option } = Select;
class Timing extends Component {
  state = {
    weekDayOpen: "",
    weekDayClose: "",
    weekEndOpen: "",
    weekEndClose: "",
    timeSlotDuration: 30,
  };
  componentDidMount() {
    let weekDay = config.outlet === "TCS" ? "weekday" : "weekdayCMPB";
    let weekEnd = config.outlet === "TCS" ? "weekend" : "weekendCMPB";
    db.collection("Timings")
      .doc(weekDay)
      .get()
      .then((doc) => {
        this.setState({
          weekDayOpen: moment()
            .hour(doc.data().opening.hour)
            .minute(doc.data().opening.minute),
          weekDayClose: moment()
            .hour(doc.data().closing.hour)
            .minute(doc.data().closing.minute),
          timeSlotDuration: doc.data().timeSlotDuration,
        });
      });
    db.collection("Timings")
      .doc(weekEnd)
      .get()
      .then((doc) => {
        this.setState({
          weekEndOpen: moment()
            .hour(doc.data().opening.hour)
            .minute(doc.data().opening.minute),
          weekEndClose: moment()
            .hour(doc.data().closing.hour)
            .minute(doc.data().closing.minute),
          timeSlotDuration: doc.data().timeSlotDuration,
        });
      });
  }

  onTimeChange = (time, cat) => {
    this.setState({
      [cat]: time,
    });
  };

  saveTimings = (type) => {
    let open = type === "weekday" ? "weekDayOpen" : "weekEndOpen";
    let close = type === "weekday" ? "weekDayClose" : "weekEndClose";
    db.collection("Timings")
      .doc(type)
      .update({
        opening: {
          hour: this.state[open].hour(),
          minute: this.state[open].minute(),
        },
        closing: {
          hour: this.state[close].hour(),
          minute: this.state[close].minute(),
        },
      });
  };

  onSelectChange = (value) => {
    let weekDay = config.outlet === "TCS" ? "weekday" : "weekdayCMPB";
    let weekEnd = config.outlet === "TCS" ? "weekend" : "weekendCMPB";
    this.setState({
      timeSlotDuration: value,
    });
    db.collection("Timings").doc(weekDay).update({
      timeSlotDuration: value,
    });
  };
  render() {
    return (
      <div className="timing-setting-page">
        <div className="opening-hours">
          <h2 className="opening-hours-title">Store Operating Hours</h2>
          <div className="opening-hours-box">
            <h3 className="opening-hours-title">Weekday</h3>
            <div className="hour-start">
              <label htmlFor="">Opening</label>{" "}
              <TimePicker
                onChange={(time, timeString) =>
                  this.onTimeChange(time, "weekDayOpen")
                }
                minuteStep={30}
                format="HH:mm"
                value={this.state.weekDayOpen}
              />
            </div>
            <div className="hour-end">
              <label htmlFor="">Closing</label>{" "}
              <TimePicker
                onChange={(time, timeString) =>
                  this.onTimeChange(time, "weekDayClose")
                }
                minuteStep={30}
                format="HH:mm"
                value={this.state.weekDayClose}
              />
            </div>
            <button onClick={() => this.saveTimings("weekday")}>Save</button>
          </div>
          <div className="opening-hours-box">
            <h3 className="opening-hours-title">Weekend</h3>
            <div className="hour-start">
              <label htmlFor="">Opening</label>{" "}
              <TimePicker
                onChange={(time, timeString) =>
                  this.onTimeChange(time, "weekEndOpen")
                }
                minuteStep={30}
                format="HH:mm"
                value={this.state.weekEndOpen}
              />
            </div>
            <div className="hour-end">
              <label htmlFor="">Closing</label>{" "}
              <TimePicker
                onChange={(time, timeString) =>
                  this.onTimeChange(time, "weekEndClose")
                }
                minuteStep={30}
                format="HH:mm"
                value={this.state.weekEndClose}
              />
            </div>
            <button onClick={() => this.saveTimings("weekend")}>Save</button>
          </div>
        </div>
        <div className="timeslot-duration">
          <label htmlFor="">Timeslot Duration</label>
          <Select
            defaultValue={this.state.timeSlotDuration}
            value={this.state.timeSlotDuration}
            style={{ width: 120 }}
            onChange={this.onSelectChange}>
            <Option value={30}>30</Option>
            <Option value={60}>60</Option>
          </Select>
        </div>
      </div>
    );
  }
}

export default Timing;
