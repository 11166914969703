import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { HashRouter as Router } from "react-router-dom";
import registerServiceWorker, { unregister } from "./registerServiceWorker";
import AppContextProvider from './components/contexts/AppContext';

ReactDOM.render(
  <Router>
    <AppContextProvider>
    <App />
    </AppContextProvider>
  </Router>,
  document.getElementById("root")
);
unregister();
