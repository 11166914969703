import React, { Component } from "react";
import { Route, Link } from "react-router-dom";
import { Drawer, Dropdown, Button } from "antd";
import Current from "./Current";
import Archived from "./Archived";
import NavBar from "./NavBar";
import { AppContext } from "./contexts/AppContext";
import Menus from "./menu/Menus";
import Menu from "./menu/Menu";
import "antd/dist/antd.css";
import Edit from "./menu/Edit";
import Timing from "./settings/Timing";
import Profiles from "./menu/Profiles";
import Assets from "./settings/Assets";
import config from "../config";

export default class Main extends Component {
  static contextType = AppContext;
  state = { visible: false, placement: "left" };
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <div id="mains">
        <NavBar
          click={this.state.visible ? this.onClose : this.showDrawer}></NavBar>
        <Drawer
          title={config.outlet === "TCS" ? "The Coastal Settlement" : "CM-PB"}
          placement={this.state.placement}
          closable={true}
          onClose={this.onClose}
          visible={this.state.visible}>
          <h3 className="nav-headers">ORDERS</h3>
          <hr />
          <ul className="sections">
            <li>
              <Link onClick={this.onClose} to="/main/orders/current">
                Active
              </Link>
            </li>
            <li>
              <Link onClick={this.onClose} to="/main/orders/archived">
                Completed
              </Link>
            </li>
          </ul>
          {this.context.admin ? (
            <React.Fragment>
              <h3 className="nav-headers">MENU</h3>
              <hr />
              <ul className="sections">
                <li>
                  <Link onClick={this.onClose} to="/main/menus">
                    Menus
                  </Link>
                </li>
                <li>
                  <Link onClick={this.onClose} to="/main/profiles">
                    Profiles
                  </Link>
                </li>
              </ul>
              <h3 className="nav-headers">Store</h3>
              <hr />
              <ul className="sections">
                <li>
                  <Link onClick={this.onClose} to="/main/assets">
                    Assets
                  </Link>
                </li>
              </ul>
            </React.Fragment>
          ) : null}
        </Drawer>
        <Route
          path="/main/orders/archived/"
          render={(props) => <Archived {...props}></Archived>}></Route>
        <Route
          path="/main/orders/current"
          render={(props) => (
            <Current {...props} playNotif={this.playNotif}></Current>
          )}></Route>
        {this.context.admin ? (
          <React.Fragment>
            <Route
              path="/main/timing"
              render={(props) => <Timing {...props}></Timing>}></Route>
            <Route
              path="/main/menus"
              render={(props) => <Menus {...props}></Menus>}></Route>
            <Route
              path="/main/profiles"
              render={(props) => <Profiles {...props}></Profiles>}></Route>
            <Route
              path="/main/edit/:id"
              render={(props) => <Edit {...props}></Edit>}></Route>
            <Route
              path="/main/menu/:id"
              render={(props) => <Menu {...props}></Menu>}></Route>
            <Route
              path="/main/assets"
              render={(props) => <Assets {...props}></Assets>}></Route>
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}
