import React, { Component } from 'react';
import { Trash2, Upload, UploadCloud, Edit2, X, PlusCircle, Plus } from 'react-feather';
import { motion } from "framer-motion"

export default class OptionsEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newChoiceType: "choice",
            topup: 0,
            newChoice: "",
            choiceList: [],
            newOptionLabel: "",
            options: [],
            hasChoices: false,
        };
    }
    componentDidMount() {
        if (this.props.edit) {
            this.setState({
                newChoiceType: this.props.data.type,
                topup: this.props.data.topup,
                newOptionLabel: this.props.data.label,
                choiceList: this.props.data.choices,
                hasChoices: this.props.data.choices.length > 1
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data && this.props.edit) {
            this.setState({
                newChoiceType: this.props.data.type,
                topup: this.props.data.topup,
                newOptionLabel: this.props.data.label,
                choiceList: this.props.data.choices,
                hasChoices: this.props.data.choices.length > 1
            })
        }
    }

    onChange = (e) => {
        console.log(e.target.value);
        this.setState({ [e.target.id]: e.target.value });
    };
    onPriceChange = (e) => {
        this.setState({ [e.target.id]: parseInt(e.target.value) });
    };
    render() {
        let { newChoiceType, newChoice, topup, choiceList, newOptionLabel, hasChoices } = this.state;
        return (
            <div className="options-group">
                <div className="form-group">
                    <label htmlFor="newChoiceType">Option Type</label>
                    <select id="newChoiceType" value={newChoiceType} onChange={this.onChange}>
                        <option value={"choice"}>Choice</option>
                        <option value={"addon"}>Addon</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="name">Option Label</label>
                    <input
                        type="text"
                        id="newOptionLabel"
                        value={newOptionLabel}
                        onChange={this.onChange}
                    />
                </div>
                {newChoiceType !== "choice" ? <div className="form-group">
                    <label htmlFor="priced">Topup Amount</label>
                    <input
                        type="number"
                        id="topup"
                        value={topup}
                        onChange={this.onPriceChange}
                    />
                </div> : null}

                {newChoiceType !== "addon" || hasChoices ? <div className="choices">
                    <label htmlFor="">Choices</label>
                    <div className="add-choice form-group">
                        <input
                            type="text"
                            id="newChoice"
                            value={newChoice}
                            onChange={this.onChange}
                            placeholder="Add New Choice"
                        />
                        <button onClick={() => {
                            let newList = [...this.state.choiceList, this.state.newChoice];
                            this.setState({ choiceList: newList, newChoice: "" });
                        }}><PlusCircle></PlusCircle></button>
                    </div>
                    <hr />
                    {choiceList.map((choice, index) => (
                        <div class="choice-item"><span>{choice}</span><X onClick={() => {
                            let currentList = [...this.state.choiceList];
                            let newList = currentList.filter((item, index2) => {
                                return index2 !== index;
                            })
                            this.setState({ choiceList: newList });
                        }}></X></div>
                    ))}
                </div> : null}
                {newChoiceType === "addon" ?
                    !hasChoices ?
                        <motion.button
                            whileHover={{ backgroundColor: "#bc9b5d", color: "#fff" }}
                            whileTap={{ scale: 0.9 }}
                            style={{ width: "100%" }}
                            onClick={() => this.setState({ hasChoices: !hasChoices })}>
                            <Plus></Plus> Add Choices
                        </motion.button> :
                        <motion.button
                            whileHover={{ backgroundColor: "#bc9b5d", color: "#fff" }}
                            whileTap={{ scale: 0.9 }}
                            style={{ width: "100%" }}
                            onClick={() => this.setState({ hasChoices: !hasChoices, choiceList: [] })}>
                            <X></X> Cancel
                        </motion.button> :
                    null}
                <div className="buttons">
                    {this.props.edit ? <motion.button
                        whileHover={{ backgroundColor: "rgb(59, 177, 104)", color: "#fff" }}
                        whileTap={{ scale: 0.9 }}
                        className="save-btn"
                        onClick={() => this.props.updateOption({ topup, label: newOptionLabel, type: newChoiceType, choices: choiceList })}>
                        <UploadCloud></UploadCloud> Save
                    </motion.button> :
                        <motion.button
                            whileHover={{ backgroundColor: "rgb(59, 177, 104)", color: "#fff" }}
                            whileTap={{ scale: 0.9 }}
                            className="save-btn"
                            onClick={() => this.props.addOption({ topup, label: newOptionLabel, type: newChoiceType, choices: choiceList })}>
                            <UploadCloud></UploadCloud> Add
                        </motion.button>}
                    {this.props.edit ? <motion.button
                        whileHover={{ backgroundColor: "#d40e40", color: "#fff" }}
                        whileTap={{ scale: 0.9 }}
                        style={{ backgroundColor: "#fff", color: "#d40e40", border: "2px solid #d40e40" }}
                        onClick={this.onDelete}
                    >
                        <Trash2></Trash2> Delete
                    </motion.button> :
                        null}
                </div>
            </div>
        )
    }
}
