import React, { Component } from "react";
import { db, storage } from "../Firebase/firebase";
import { message, DefaultProgress } from "flwww";
import config from "../../config";
import {
  Trash2,
  Upload,
  UploadCloud,
  Edit2,
  X,
  PlusCircle,
} from "react-feather";

export default class Assets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      desktopImageFile: null,
      mobileImageFile: null,
      carouselImageFile: null,
      carouselMobileImageFile: null,
      desktopLandingProgress: null,
      mobileLandingProgress: null,
      carouselProgress: null,
      carouselMobileProgress: null,
      desktopLanding: "",
      mobileLanding: "",
      homeCarousel: [],
      allCarousel: [],
      homeCarouselMobile: [],
      allCarouselMobile: [],
    };
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.handleCarouselUpload = this.handleCarouselUpload.bind(this);
  }

  componentDidMount() {
    let assetDoc = config.outlet === "TCS" ? "assetsTCS" : "assetsCMPB";
    db.collection("Settings")
      .doc(assetDoc)
      .onSnapshot((doc) => {
        this.setState(doc.data());
      });
  }

  handleFileChange = (e, banner) => {
    if (e.target.files[0]) {
      const imageFile = e.target.files[0];
      this.setState({
        desktopImageFile: banner === "desktop" ? imageFile : null,
        mobileImageFile: banner === "mobile" ? imageFile : null,
        carouselImageFile: banner === "carousel" ? imageFile : null,
        carouselMobileImageFile:
          banner === "carousel-mobile" ? imageFile : null,
      });
    }
  };

  handleUpload = (e, folder, banner) => {
    let assetDoc = config.outlet === "TCS" ? "assetsTCS" : "assetsCMPB";
    e.preventDefault();
    const { desktopImageFile, mobileImageFile } = this.state;
    let propertyToUpdate =
      banner === "desktop" ? "desktopLanding" : "mobileLanding";
    let fileToUpload =
      banner === "desktop" ? desktopImageFile : mobileImageFile;
    const uploadTask = storage
      .ref(`${folder}/${fileToUpload.name}`)
      .put(fileToUpload);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        //progress functions
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        this.setState({
          desktopLandingProgress: banner === "desktop" ? progress : null,
          mobileLandingProgress: banner === "mobile" ? progress : null,
        });
      },
      (error) => {
        console.log(error);
      },
      () => {
        storage
          .ref(folder)
          .child(fileToUpload.name)
          .getDownloadURL()
          .then((url) => {
            // Insert url into an <img> tag to "download"
            db.collection("Settings")
              .doc(assetDoc)
              .update({ [propertyToUpdate]: url });
            this.setState({ url });
            message("Upload Success!", "success", 4);
          });
      }
    );
  };

  handleCarouselUpload = (e, folder, type) => {
    let assetDoc = config.outlet === "TCS" ? "assetsTCS" : "assetsCMPB";
    e.preventDefault();
    const { carouselImageFile, carouselMobileImageFile } = this.state;
    let fileToUse =
      type === "carousel" ? carouselImageFile : carouselMobileImageFile;
    const uploadTask = storage
      .ref(`${folder}/${fileToUse.name}`)
      .put(fileToUse);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        //progress functions
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        this.setState({
          carouselProgress: type === "carousel" ? progress : null,
          carouselMobileProgress: type === "carousel-mobile" ? progress : null,
        });
      },
      (error) => {
        console.log(error);
      },
      () => {
        storage
          .ref(folder)
          .child(fileToUse.name)
          .getDownloadURL()
          .then((url) => {
            // Insert url into an <img> tag to "download"
            let bannerList =
              type === "carousel"
                ? [...this.state.allCarousel]
                : [...this.state.allCarouselMobile];
            bannerList.push(url);
            if (type === "carousel") {
              db.collection("Settings")
                .doc(assetDoc)
                .update({ allCarousel: bannerList });
            } else {
              db.collection("Settings")
                .doc(assetDoc)
                .update({ allCarouselMobile: bannerList });
            }

            this.setState({ url });
            message("Upload Success!", "success", 4);
          });
      }
    );
  };

  toggleBanner = (banner) => {
    let assetDoc = config.outlet === "TCS" ? "assetsTCS" : "assetsCMPB";
    let bannerIndex = this.state.homeCarousel.findIndex(
      (item) => item === banner
    );
    let newBanner = [...this.state.homeCarousel];
    if (bannerIndex === -1) {
      newBanner.push(banner);
      db.collection("Settings")
        .doc(assetDoc)
        .update({ homeCarousel: newBanner });
    } else {
      newBanner.splice(bannerIndex, 1);
      db.collection("Settings")
        .doc(assetDoc)
        .update({ homeCarousel: newBanner });
    }
  };

  toggleBannerMobile = (banner) => {
    let assetDoc = config.outlet === "TCS" ? "assetsTCS" : "assetsCMPB";
    let bannerIndex = this.state.homeCarouselMobile.findIndex(
      (item) => item === banner
    );
    let newBanner = [...this.state.homeCarouselMobile];
    if (bannerIndex === -1) {
      newBanner.push(banner);
      db.collection("Settings")
        .doc(assetDoc)
        .update({ homeCarouselMobile: newBanner });
    } else {
      newBanner.splice(bannerIndex, 1);
      db.collection("Settings")
        .doc(assetDoc)
        .update({ homeCarouselMobile: newBanner });
    }
  };

  deleteBanner = (banner) => {
    let assetDoc = config.outlet === "TCS" ? "assetsTCS" : "assetsCMPB";
    let bannerIndex = this.state.allCarousel.findIndex(
      (item) => item === banner
    );
    let newBanner = [...this.state.allCarousel];
    newBanner.splice(bannerIndex, 1);
    db.collection("Settings").doc(assetDoc).update({ allCarousel: newBanner });
  };

  deleteBannerMobile = (banner) => {
    let assetDoc = config.outlet === "TCS" ? "assetsTCS" : "assetsCMPB";
    let bannerIndex = this.state.allCarouselMobile.findIndex(
      (item) => item === banner
    );
    let newBanner = [...this.state.allCarouselMobile];
    newBanner.splice(bannerIndex, 1);
    db.collection("Settings")
      .doc(assetDoc)
      .update({ allCarouselMobile: newBanner });
  };

  render() {
    let {
      desktopLandingProgress,
      mobileLandingProgress,
      carouselProgress,
      carouselMobileProgress,
      desktopLanding,
      mobileLanding,
      homeCarousel,
      allCarousel,
      allCarouselMobile,
      homeCarouselMobile,
    } = this.state;
    return (
      <div className="assets-page">
        <div className="landing-banners">
          <h3>Landing Banners</h3>
          <hr />
          <div className="banner-previews">
            <div className="desktop-landing-preview">
              <img className="banner-preview-img" src={desktopLanding} alt="" />
            </div>
            <div className="mobile-landing-preview">
              <img className="banner-preview-img" src={mobileLanding} alt="" />
            </div>
          </div>
          <div className="banner-upload-actions">
            <div className="file-form-group">
              <input
                type="file"
                onChange={(e) => this.handleFileChange(e, "desktop")}
              />
              <button
                onClick={(e) => this.handleUpload(e, "landing", "desktop")}>
                <Upload></Upload> Upload
              </button>
              {desktopLandingProgress ? (
                <DefaultProgress
                  prcnt={desktopLandingProgress}></DefaultProgress>
              ) : null}
            </div>
            <div className="file-form-group">
              <input
                type="file"
                onChange={(e) => this.handleFileChange(e, "mobile")}
              />
              <button
                onClick={(e) => this.handleUpload(e, "landing", "mobile")}>
                <Upload></Upload> Upload
              </button>
              {mobileLandingProgress ? (
                <DefaultProgress
                  prcnt={mobileLandingProgress}></DefaultProgress>
              ) : null}
            </div>
          </div>
        </div>
        <div className="carousel-banners">
          <h3>Carousel Banners</h3>
          <hr />
          <div className="carousel-banner-images">
            <div className="carousel-banners-list">
              <div className="banner-list">
                {allCarousel.map((banner) => {
                  return (
                    <div className="banner-container">
                      <img
                        style={
                          homeCarousel.findIndex((item) => item === banner) !==
                          -1
                            ? { border: "4px solid #bc9b5d" }
                            : {}
                        }
                        onClick={() => this.toggleBanner(banner)}
                        src={banner}></img>
                      <button className="delete-banner">X</button>
                    </div>
                  );
                })}
              </div>
              <div className="banner-upload-actions">
                <div className="file-form-group">
                  <input
                    type="file"
                    onChange={(e) => this.handleFileChange(e, "carousel")}
                  />
                  <button
                    onClick={(e) =>
                      this.handleCarouselUpload(e, "carousel", "carousel")
                    }>
                    <Upload></Upload> Upload
                  </button>
                </div>
                {carouselProgress ? (
                  <DefaultProgress prcnt={carouselProgress}></DefaultProgress>
                ) : null}
              </div>
            </div>
            <div className="carousel-active">
              {homeCarousel.map((banner) => {
                return <img src={banner}></img>;
              })}
            </div>
          </div>
          <hr />
          <div className="carousel-banner-images">
            <div className="carousel-banners-list">
              <div className="banner-list">
                {allCarouselMobile.map((banner) => {
                  return (
                    <div className="banner-container">
                      <img
                        style={
                          homeCarouselMobile.findIndex(
                            (item) => item === banner
                          ) !== -1
                            ? { border: "2px solid #bc9b5d" }
                            : {}
                        }
                        onClick={() => this.toggleBannerMobile(banner)}
                        src={banner}></img>
                      <button
                        className="delete-banner "
                        onClick={() => this.deleteBannerMobile(banner)}>
                        X
                      </button>
                    </div>
                  );
                })}
              </div>
              <div className="banner-upload-actions">
                <div className="file-form-group">
                  <input
                    type="file"
                    onChange={(e) =>
                      this.handleFileChange(e, "carousel-mobile")
                    }
                  />
                  <button
                    onClick={(e) =>
                      this.handleCarouselUpload(
                        e,
                        "carousel",
                        "carousel-mobile"
                      )
                    }>
                    <Upload></Upload> Upload
                  </button>
                </div>
                {carouselMobileProgress ? (
                  <DefaultProgress
                    prcnt={carouselMobileProgress}></DefaultProgress>
                ) : null}
              </div>
            </div>
            <div className="carousel-active">
              {homeCarouselMobile.map((banner) => {
                return <img src={banner}></img>;
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
