import React, { Component } from "react";
import { db } from "../Firebase/firebase";
import { Link } from "react-router-dom";
import { Plus } from "react-feather";
import config from "../../config";

export default class Menus extends Component {
  state = {
    menus: [],
    newMenu: "",
    createMenu: false,
  };
  componentDidMount() {
    db.collection("Menus")
      .where("outlet", "==", config.outlet)
      .onSnapshot((querySnapShot) => {
        let menus = [];
        querySnapShot.forEach((item) => {
          let itemData = item.data();
          menus.push({ ...itemData, id: item.id });
        });
        this.setState({
          menus,
        });
      });
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  selectEdit = (id) => {
    this.setState({
      selected: id,
    });
  };

  createNewMenu = () => {
    db.collection("Menus").add({
      active: true,
      categories: [],
      name: this.state.newMenu,
      outlet: config.outlet,
    });

    this.setState({ createMenu: false });
  };
  render() {
    return (
      <div id="menus">
        {this.state.menus.map((menu) => {
          return (
            <span className="menu-link">
              <Link to={`/main/menu/${menu.id}`}>{menu.name}</Link>
            </span>
          );
        })}
        <span className="menu-link create-new-menu">
          {!this.state.createMenu ? (
            <Link onClick={() => this.setState({ createMenu: true })}>
              {" "}
              <Plus></Plus> Create New Menu
            </Link>
          ) : (
            <div className="createMenu">
              <div className="form-group">
                <label htmlFor="">New Menu Name</label>
                <input
                  type="text"
                  name="newMenu"
                  id="newMenu"
                  value={this.state.newMenu}
                  onChange={this.onChange}
                />
              </div>
              <button onClick={this.createNewMenu}>Submit</button>
            </div>
          )}
        </span>
      </div>
    );
  }
}
