import React, { Component } from "react";
import { Route, withRouter, Redirect } from "react-router-dom";
import "./App.css";
import { auth } from "./components/Firebase/firebase";
import Login from "./components/Login";
import PrivateRoute from "./components/PrivateRoute";
import Main from "./components/Main";
import {AppContext} from './components/contexts/AppContext'

class App extends Component {
  static contextType = AppContext;
  state = {
    auth: true,
    user: null,
  };
  componentDidMount() {
    /*auth
      .createUserWithEmailAndPassword("andreweijie@gmail.com", "Many@1234")
      .catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // ...
        console.log(errorMessage);
      });*/
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdTokenResult().then(idTokenResult => {
          if (idTokenResult.claims.admin) {
            this.context.setAdmin(true);
            this.props.history.replace("/main/orders/current");
          }
          else {
            this.context.setAdmin(false);
            this.props.history.replace("/main/orders/current");
          }
        })
        this.setState({ user, auth: true });
      } else {
        this.setState({ auth: false });
      }
    });
  }

  render() {
    return (
        <div className="App">
          {this.state.auth ? <button id="logout-button" onClick={() => auth.signOut()}>logout</button>:null}
          <Route exact path="/" render={() => <Redirect to="/main" />} />
          <Route exact path="/login" render={(props) => <Login {...props} />} />
          <PrivateRoute
            path="/main"
            component={Main}
            auth={this.state.auth}
          ></PrivateRoute>
        </div>
    );
  }
}

export default withRouter(App);
