import React, { Component } from "react";
import { db } from "../Firebase/firebase";
import Timings from "./Timings";
import { TwitterPicker } from "react-color";
import { Plus } from "react-feather";
import { Popover } from "antd";
import config from "../../config";

export default class Profiles extends Component {
  state = {
    profiles: [],
    menus: [],
    currentMenus: [],
    currentColor: "",
    selectedProfile: null,
    profileName: "",
    createProfile: false,
    displayName: "",
    profileMenus: [],
  };

  componentDidMount() {
    db.collection("Profiles")
      .where("outlet", "==", config.outlet)
      .onSnapshot((querySnapShot) => {
        let profiles = [];
        querySnapShot.forEach((profile) => {
          let profileData = profile.data();
          profileData.id = profile.id;
          profiles.push(profileData);
        });
        this.setState({ profiles });
      });
    db.collection("Menus")
      .where("outlet", "==", config.outlet)
      .onSnapshot((querySnapShot) => {
        let menus = [];
        querySnapShot.forEach((menu) => {
          let menuData = menu.data();
          menuData.id = menu.id;
          menus.push(menuData);
        });
        this.setState({ menus });
      });
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  loadProfile = (profile) => {
    this.setState(
      {
        selectedProfile: profile,
        currentColor: profile.profileColor,
        profileName: profile.name,
        displayName: profile.displayName,
      },
      () => {
        this.setState({
          profileMenus: this.state.selectedProfile.menus,
        });
      }
    );
  };

  changeProfileColor = (color) => {
    db.collection("Profiles").doc(this.state.selectedProfile.id).update({
      profileColor: color.hex,
    });
    this.setState({ currentColor: color.hex });
  };

  toggleMenu = (menu) => {
    let profileMenus = [...this.state.profileMenus];
    let indexIfExists = profileMenus.findIndex((item) => item === menu.id);
    if (indexIfExists === -1) {
      profileMenus.push(menu.id);
    } else {
      profileMenus.splice(indexIfExists, 1);
    }
    this.setState({ profileMenus }, () => {
      db.collection("Profiles").doc(this.state.selectedProfile.id).update({
        menus: profileMenus,
      });
    });
  };

  createNewProfile = () => {
    db.collection("Profiles").add({
      displayName: this.state.displayName,
      name: this.state.profileName,
      menus: [],
      outlet: config.outlet,
      profileColor: "#000000",
    });

    this.setState({
      createProfile: false,
      displayName: "",
      profileName: "",
    });
  };

  render() {
    let {
      profiles,
      selectedProfile,
      profileName,
      menus,
      displayName,
      profileMenus,
      createProfile,
    } = this.state;
    return (
      <div className="profiles-page">
        <div className="profile-info">
          <div className="profile-selector-box">
            <div className="profile-page-header">
              <h2>Menu Profiles</h2>

              <Popover
                placement="bottomRight"
                content={
                  <div className="create-profile">
                    <div className="form-group">
                      <label>Profile Name</label>
                      <input
                        type="text"
                        name="profileName"
                        id="profileName"
                        value={profileName}
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Display Name</label>
                      <input
                        type="text"
                        name="displayName"
                        id="displayName"
                        value={displayName}
                        onChange={this.onChange}
                      />
                    </div>
                    <button
                      className="new-profile-submit"
                      onClick={this.createNewProfile}>
                      Submit
                    </button>
                  </div>
                }
                trigger="click">
                <button
                  onClick={() =>
                    this.setState({
                      createProfile: true,
                      selectedProfile: null,
                      currentColor: "",
                      profileName: "",
                      displayName: "",
                    })
                  }>
                  <Plus></Plus>Create New Profile
                </button>
              </Popover>
            </div>
            <div className="profile-list">
              {profiles.map((profile) => {
                return (
                  <button
                    key={profile.name}
                    style={{ borderLeftColor: profile.profileColor }}
                    onClick={() => this.loadProfile(profile)}>
                    {profile.name}
                  </button>
                );
              })}
            </div>
          </div>
          <hr />
          {selectedProfile ? (
            <div className="profile-info-box">
              <div className="profile-header">
                <div className="names">
                  <div className="name-inputs">
                    <label htmlFor="profileName">Profile Name</label>
                    <div className="input-group">
                      <input
                        type="text"
                        name="profileName"
                        id="profileName"
                        value={profileName}
                        onChange={this.onChange}
                      />
                      <button>Change</button>
                    </div>
                  </div>
                  <div className="name-inputs">
                    <label htmlFor="displayName">Display Name</label>
                    <div className="input-group">
                      <input
                        type="text"
                        name="displayName"
                        id="displayName"
                        value={displayName}
                        onChange={this.onChange}
                      />
                      <button>Change</button>
                    </div>
                  </div>
                </div>
                <TwitterPicker
                  triangle="hide"
                  color={this.state.currentColor}
                  onChange={this.changeProfileColor}></TwitterPicker>
                <div
                  className="color-selected-box"
                  style={{ backgroundColor: this.state.currentColor }}></div>
              </div>
              <hr />
              <div className="profile-menus">
                <div className="avail-menus">
                  <h3>All Menus</h3>
                  {menus.map((menu) => {
                    return (
                      <button
                        style={
                          profileMenus.findIndex((item) => item === menu.id) !==
                          -1
                            ? { border: `3px solid ${this.state.currentColor}` }
                            : {}
                        }
                        onClick={() => this.toggleMenu(menu)}>
                        {menu.name}
                      </button>
                    );
                  })}
                </div>
                <div className="selected-menus">
                  <h3>Menus In Profile</h3>
                  {profileMenus.map((menu) => {
                    return (
                      <button className="active-menu-in-list">
                        {menus.find((item) => item.id === menu).name}
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <Timings
          profileData={this.state.profiles}
          profile={this.state.selectedProfile}></Timings>
      </div>
    );
  }
}
