import React, { Component } from "react";
import OrderSummary from "./OrderSummary";
import { db } from "./Firebase/firebase";
import config from "../config";
import { AppContext } from "./contexts/AppContext";
export default class Current extends Component {
  static contextType = AppContext;
  state = {
    orders: [],
  };
  componentDidMount() {
    this.getArchivedOrders();
  }

  getArchivedOrders = () => {
    db.collection("Orders")
      .orderBy("createdAt", "desc")
      .where("outlet", "==", config.outlet)
      .where("status", "==", "Completed")
      .limit(50)
      .onSnapshot((querySnapShot) => {
        let orders = [];
        querySnapShot.forEach((item) => {
          let itemData = item.data();
          orders.push({ ...itemData, id: item.id });
        });
        this.setState({
          orders: orders,
        });
      });
  };
  render() {
    return (
      <div id="archived">
        {this.state.orders.map((order) => {
          return <OrderSummary data={order}></OrderSummary>;
        })}
      </div>
    );
  }
}
