import React from "react";
import { Link } from "react-router-dom";
import { Trash2, Upload, UploadCloud, Edit2, X } from 'react-feather';

const MenuItem = (props) => {
  let { data } = props;
  return (
    <div className="menu-item">
      <img src={data.url} alt="" />
      <h4>{data.name}</h4>
      <h4>{data.category}</h4>
      <h4>${data.priced}.00</h4>
      <Edit2 onClick={() => props.selectEdit(data.id)}></Edit2>
    </div>
  );
};

export default MenuItem;
